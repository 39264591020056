@import url('https://fonts.googleapis.com/css2?family=Instrument+Sans:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Instrument+Sans:ital,wght@0,400;1,400&family=Mansalva&display=swap');


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.scrollable-container {
  max-height: 300px; /* Adjust this value as needed */
  overflow-y: auto;
}

.custom-checkbox {
  /* Hide the default checkbox */
  cursor: pointer;
}

.fade-section {
  opacity: 0;
  transform: translateY(20px); /* Slightly slide up */
  transition: opacity 0.6s ease, transform 0.6s ease;
}
.fade-section.visible {
  opacity: 1;
  transform: translateY(0);
}

.meet-rugman-text {
  font-family: "Instrument Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}

.mansalva-text {
  font-family: "Mansalva", cursive;
  font-weight: 400;
  font-style: normal;
}
