@tailwind base;
@tailwind components;
@tailwind utilities;

/* Layered Background Animations */
@keyframes float {
  0%, 100% {
    transform: translateY(-100px);
  }
  50% {
    transform: translateY(50px);
  }
}

@keyframes floatSlow {
  0%, 100% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(200px);
  }
}

@keyframes sway {
  0%, 100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-10px);
  }
}

.float {
  animation: float 6s ease-in-out infinite;
}

.float-slow {
  animation: floatSlow 8s ease-in-out infinite;
}

.sway {
  animation: sway 4s ease-in-out infinite;
}

.float-delay-1 {
  animation-delay: 2s;
}

.float-delay-2 {
  animation-delay: 2s;
}

.float-delay-3 {
  animation-delay: 3s;
}

/* Parallax classes */
.parallax-wrapper {
  height: 100%;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
  overflow: hidden;
  position: relative;
}

.parallax-layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  will-change: transform;
  background-size: cover !important;
  background-position: center !important;
}

/* Responsive asteroid layers */
.responsive-asteroid {
  background-size: 100% auto !important;
}


/* Section Divider */
.section-divider {
  position: relative;
  width: 100%;
  height: 100px;
  margin-top: -50px;
  margin-bottom: -50px;
  z-index: 10;
}