@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*:before,
*:after {
  @apply cursor-default;
}

/* Apply pointer cursor to clickable elements */
button,
[role="button"],
a,
select,
input[type="submit"],
input[type="button"],
input[type="radio"],
input[type="checkbox"],
[onclick],
[class*="clickable"],
[class*="button"] {
  @apply cursor-pointer;
}
